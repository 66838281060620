<template>
  <v-container fluid>
    <div>
      <v-form
        ref="form"
        v-model="valid"
        class="pt-1"
      >
        <v-row wrap no-gutters class="fill-height">
          <v-col
            cols="12"
            sm="12"
            md="10"
            class="fill-height"
          >
            <v-card class="pa-2 fill-height">
              <v-row wrap no-gutters justify="space-between" class="pb-1">
                <v-col
                  cols="12"
                  class="pr-md-1 mb-2"
                >
                  <div class="d-flex align-center justify-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          text
                          large
                          v-bind="attrs"
                          v-on="on"
                          @click="fetchData()"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $lang.labels.reloadData }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  class="pr-md-1"
                >
                  <div class="d-flex">
                    <v-autocomplete
                      v-model="storage.processId"
                      outlined
                      dense
                      :items="processes"
                      :loading="isLoadingProcesses"
                      :search-input.sync="searchProcesses"
                      hide-no-data
                      clearable
                      item-text="name"
                      item-value="id"
                      :rules="[v => !!v || $lang.labels.required]"
                      :label="$lang.labels.process"
                      :placeholder="$lang.actions.startTyping"
                      prepend-inner-icon="mdi-cloud-search-outline"
                      :persistent-hint="isEdit"
                      :hint="formatHint"
                      append-icon="mdi-vector-combine"
                      class="mb-1"
                      @click:append="copyStorageName()"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.name }}
                      </template>
                    </v-autocomplete>
                    <v-btn
                      text
                      small
                      dark
                      color="primary"
                      class="ml-1 mt-1"
                      @click="openProcessInNewTabById(storage.processId)"
                    >{{ $lang.labels.open }}</v-btn>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pl-md-1"
                >
                  <v-datetime-picker
                    v-model="expireOnTemp"
                    :label="$lang.labels.expireOn"
                    no-title
                    :text-field-props="{ outlined: true, dense: true }"
                  >
                    <template v-slot:dateIcon="{}">
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <template v-slot:timeIcon="{}">
                      <v-icon>mdi-clock-outline</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pr-md-1"
                >
                  <v-text-field
                    v-model="storage.key"
                    outlined
                    dense
                    :rules="[v => !!v || $lang.labels.required]"
                    :label="$lang.labels.key"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pl-md-1"
                >
                  <v-text-field
                    v-model="storage.value"
                    outlined
                    dense
                    :rules="[v => !!v || $lang.labels.required]"
                    :label="$lang.labels.value"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mb-2"></v-divider>
              <v-row no-gutters wrap justify="space-between">
                <v-col
                  cols="6"
                  class="text-left"
                >
                  <div class="d-inline-flex align-center">
                    <v-btn
                      class="button-default-width"
                      color="secondary"
                      :to="{ name: 'storage', params: { lang: $lang.current_lang } }"
                    >
                      <v-icon
                        right
                        dark
                        class="ma-0"
                      >
                        mdi-arrow-left
                      </v-icon>
                      <span class="ml-1">{{ $lang.actions.back }}</span>
                    </v-btn>
                    <action-button-with-confirmation
                      v-if="isEdit"
                      :action-text="$lang.actions.areYouSureYouWantToDelete"
                      :action-text-suffix="''"
                      :title="$lang.actions.delete"
                      type="storage"
                      class="ml-2"
                      :is-disabled="false"
                      :button-text="$lang.actions.delete"
                      :button-color="'error'"
                      :data-cy="'storage-delete'"
                      :forced-option="true"
                      :trigger-force-logic="triggerForceLogic"
                      :regular-delete-errors-usages="regularDeleteErrorsUsages"
                      :delete-success="deleteSuccess"
                      @closeAfterDelete="$router.push({ name: 'storage' })"
                      @submit="deleteStorageFunct($event)"
                      @closeDialog="''"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    :disabled="!valid || !userCanEdit"
                    color="success"
                    class="button-default-width ml-2"
                    @click="submit()"
                  >
                    {{ $lang.actions.submit }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import {
  createStorageUsingPOST as createStorage,
  deleteStorageUsingDELETE as deleteStorage,
  getProcessByIdUsingGET as getProcess,
  getProcessesUsingGET as getProcesses,
  getStorageByIdUsingGET as getStorage,
  updateStorageUsingPUT as updateStorage
} from '@/utils/api'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import copy from 'copy-to-clipboard'
import { mapActions } from 'vuex'

export default {
  components: { ActionButtonWithConfirmation },
  data() {
    return {
      isEdit: false,
      valid: true,
      err: '',
      success: '',
      loading: true,
      storage: {
        key: '',
        createdOn: '',
        expireOn: '',
        modifiedOn: '',
        id: 0,
        processId: 0,
        value: ''
      },
      storageProcessName: '',
      expireOnTemp: '',
      processes: [],
      searchProcesses: '',
      isLoadingProcesses: false,
      userCanEdit: true,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.storage.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.storage.modifiedOn)}, ID: ${this.storage.id}`
    }
  },
  watch: {
    'storage.processId': {
      handler(val) {
        if (val) {
          this.storageProcessName = this.processes.find((p) => p.id === val)?.name
        }
      }
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'storageEdit'

    this.loading = true

    if (this.isEdit && this.$route.params.id) {
      this.fetchData()
    } else if (this.$route.params.restore) {
      this.storage = this.$route.params.restore
      this.storage.id = 0
      this.expireOnTemp = new Date(this.storage.expireOn)

      getProcess({ id: this.storage.processId })
        .then((res) => {
          this.processes = [res.data.data]
          this.storageProcessName = res.data.data.name
        })
        .catch((error) => {
          console.log(error)
        })
      this.loading = false
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    copyStorageName() {
      if (this.storageProcessName) {
        copy(this.storageProcessName)
        this.addSnackbar({
          message:  this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message:  this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    openProcessInNewTabById(id) {
      window.open(
        `/${localStorage.selectedLanguage || 'en'}/processes/edit/${id}`,
        '_blank'
      )
    },
    fetchData() {
      getStorage({ id: this.$route.params.id })
        .then((res) => {
          this.storage = res.data.data
          this.expireOnTemp = new Date(this.storage.expireOn)

          getProcess({ id: this.storage.processId })
            .then((res) => {
              this.processes = [res.data.data]
              this.storageProcessName = res.data.data.name
            })
            .catch((error) => {
              console.log(error)
            })

          this.loading = false
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    deleteStorageFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteStorage({ id: this.storage.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res?.data?.data?.usages || []
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    async submit() {
      this.err = ''

      this.lock = true

      let res = null

      const { id } = this.storage

      if (!this.isEdit) {
        delete this.storage.id
      }
      delete this.storage.createdOn
      delete this.storage.modifiedOn

      this.storage.expireOn = this.expireOnTemp.toISOString()

      try {

        res = this.isEdit ? await updateStorage({ id, body: this.storage }) : await createStorage({ body: this.storage })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.storageUpdate : this.$lang.errors.storageCreate)

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.storageUpdated : this.$lang.success.storageCreated

        this.addSnackbar({
          message: this.success,
          timeout: 5000,
          color: 'success'
        })
        await this.$router.push({
          name: 'storage',
          params: {
            message: this.success
          }
        })

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  }
}
</script>
<style lang="scss">
.side-border {
  &-left {
    border-left: 1px solid var(--v-secondary-base);
  }
  &-right {
    border-right: 1px solid var(--v-secondary-base);
  }
}

.hideNumberArrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
